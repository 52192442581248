import { useEffect } from "react";
import { Header, Main, Footer } from "./index";
import { encryptData, setToStorage } from "../../assets";
import Swal from "sweetalert2";
import { useSearchParams } from "react-router-dom";
import { makePostAPICall } from "../../helpers/ApiHelpers";

const Home = () => {
	const [searchParams] = useSearchParams();

	useEffect(() => {
		if (searchParams.has("token") && searchParams.has("school_code")) {
			makePostAPICall(
				"admins.php",
				{
					action: "authenticate_token",
					token: searchParams.get("token"),
					school_code: searchParams.get("school_code"),
				},
				(response) => {
					const data = response.data;

					if (data.type === "staff" || data.type === "admin") {
						let classes = data.classes.sort(function (a, b) {
							return a?.class.localeCompare(b?.class);
						});

						let terms = data.terms_sessions
							.sort(function (a, b) {
								return a.session_name.localeCompare(b.session_name);
							})
							.reverse();

						let subjects = data.subjects.sort(function (a, b) {
							return a?.title.localeCompare(b?.title);
						});

						setToStorage("subjects", subjects);
						setToStorage("session", data.current_session);
						setToStorage("classes", classes);
						setToStorage("levels", data.levels);
						setToStorage("terms", terms);
						setToStorage("teacherUser", data.staff);
						setToStorage("teachersToken", data.token);
						setToStorage("school", data.school);
						setToStorage("school_code", data.school.school_code);

						window.location.href = "/admin/";
					} else if (data.type === "student") {
						setToStorage("studentToken", response.data.token);
						setToStorage("studentUser", encryptData(response.data.student));
						setToStorage("school", encryptData(data.school));
						setToStorage("school_code", data.school.school_code);

						window.location.href = "/home";
					}
				},
				(error) => {
					Swal.fire({
						icon: "error",
						toast: true,
						position: "top-end",
						title: error.msg,
						showConfirmButton: false,
						timer: 2500,
					}).then(() => {
						// removeItemsFromStorage(["subjects", "studentUser", "currentTerm", "terms", "token"]);
						// window.location.href = "/login";
					});
				}
			);
		}
	}, [searchParams]);

	return (
		<>
			<Header />
			<Main />
			<Footer />
		</>
	);
};

export default Home;
